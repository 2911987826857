export const FormAction = {
    ADD: "ADD",
    EDIT: "EDIT",
    DUPLICATE : "DUPLICATE"
};

export const StepperSteps = {
    TEST_CONFIGURATIONS: { key: 'TEST_CONFIGURATIONS', label: 'Test Configurations' },
    QUESTION_SET: { key: 'QUESTION_SET', label: 'Question Set' },
}

export const StagesType = {
    TEST_CONFIGURATIONS : "TEST_CONFIGURATIONS",
    QUESTION_SET : "QUESTION_SET",
}

export const PRESCREENING_TYPE = {
    TEST_VALIDITY : "testValidity",
    TEST_START_TIMESTAMP : "testStartTimestamp",
}