import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled as styled_mui } from "@mui/material/styles";
import durationIcon from "../../../../../assets/images/duration-icon.svg";
import "./QuestionList.scss";
const CustomCheckBox = styled_mui(Checkbox)({
  "&.Mui-checked": {
    color: "#123FBC",
    boxShadow: "none",
  },
});
const Accordion = styled_mui((props) => <MuiAccordion {...props} />)(
  ({ theme }) => ({
    "&:nth-child(2n+1)": {
      background: "#F8F9FD",
    },
    "& .MuiCollapse-root": {},
  })
);
const AccordionSummary = styled_mui((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({}));
export default function QuestionList({type,questionList,selectedQuestion,setSelectedQuestion ,topic, currentPage, questionPerPage}) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCheckbox = (item)=>{

      let obj = selectedQuestion?.find(data=>data?.questionId===item?.id); 
     if(!obj?.questionId)
     {
       obj = {
            isChecked : true,
            question : item,
            questionId : item?.id
       }
       selectedQuestion?.push(obj);
     }
     else
     {
       obj.isChecked = !obj?.isChecked;
     }
     console.log("selectedQuestion",selectedQuestion);
     setSelectedQuestion(()=>{
      return [...selectedQuestion];
    });
  }

  
  
  return (
    <div className="question-bank-accordion">
      {questionList && questionList?.length > 0
        ? questionList?.map((item, index) => {
            return (
              <>
            {selectedQuestion?.find(ques=>ques?.questionId===item?.id)?.question?.topic && topic && selectedQuestion?.find(ques=>ques?.questionId===item?.id)?.question?.topic !== topic
             ? <></> 
             : <Accordion
                expanded={expanded === "panel" + (index + 1)}
                onChange={handleChange("panel" + (index + 1))}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="col-style zero-col">
                    <CustomCheckBox
                      checked={selectedQuestion?.find(ques=>ques?.questionId===item?.id)?.isChecked?"checked":""}
                      size="small"
                      onChange={(e) => {
                        setExpanded(false);
                        handleCheckbox(item);
                      }}
                      color="primary"
                    />
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography className="font-style  col-style first-col font-13 font-400">
                    {" "}
                    {(currentPage * questionPerPage) + index + 1}.&nbsp;&nbsp;
                    {item?.question_text}{" "}
                  </Typography>
                  <Box className="column-box">
                  <Typography className="role second-col">
                     {item?.role?.length>0 ? item?.role : ""} 
                    &nbsp;
                  </Typography>
                  <Typography
                    className={
                      "font-style  col-style third-col font-13 font-600 " +
                      (item?.difficulty_level?.toLowerCase() === "easy"
                        ? "fg-easy"
                        : item?.difficulty_level?.toLowerCase() === "medium"
                        ? "fg-medium"
                        : "fg-hard")
                    }
                  >
                    {item?.difficulty_level ?  " - " +item?.difficulty_level : ""}
                  </Typography>
                  <Typography className="font-11 font-400 col-style fourth-col font-style ">
                  {item?.tags?.length>0?item?.tags[0]?.length>12?  '('+ item?.tags[0]?.slice(0,12)+"...)":  '('+ item?.tags[0] +")":""}
                  </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="collapse-content font-style font-13 font-400">
                    {item?.question?.name && item?.question?.name?.length > 0
                      ? item?.question?.statement
                      : item?.solution?.solution1?.approach?item?.solution?.solution1?.approach:""}
                  </Typography>
                </AccordionDetails>
              </Accordion> 
             }
              </>
            );
          })
        : ""}
    </div>
  );
}
