import axios from "./axios";
import {POSITION_STATUS} from "../constants/positions"

export const getAllRolesRoundCategoriesDict = () => {
    return axios.get("/role_round_category_resources/v1/dict")
        .then((response) => {
            return response.data;
        })
        .catch(err => {
            return err.response;
        })
};


export const closePosition = (positionId, payload) => {
    return axios.post(`/position/${positionId}/${POSITION_STATUS.CLOSED}`, payload)
        .then((response) => {
            return response;
        })
};

export const openPosition = (positionId, payload) => {
    return axios.post(`/position/${positionId}/${POSITION_STATUS.OPEN}`, payload)
        .then((response) => {
            return response;
        })
};

export const getAllRolesRoundCategoriesDictV2 = () => {
    return axios.get('/role_round_category_resources/v2/dict')
        .then((response) => {
            return response.data;
        })
        .catch(err => {
            return err.response;
        })
};

export const getAllRolesRoundCategoriesResources = () => {
    return axios.get('/role_round_category_resources/all')
        .then((response) => {
            return response.data;
        })
        .catch(err => {
            return err.response;
        })
};

export const getPrescreeningPosition = (position_id,payload={}) => {
    return axios.post(`/position_pre_screening/${position_id}`,payload)
    .then((response) => {
        return response;
    })
    .catch(err => {
        return err.response;
    })
}
