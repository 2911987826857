import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import "./Preview.scss";
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import logoiv from "../../../../assets/images/logo-iv.png";
import copy from "../../../../assets/images/copy.svg";
import TaskAlt from '@mui/icons-material/TaskAlt';
import moment from "moment-timezone";

const Preview = ({ dataPreview, previewShareLinkData, copyLinkVisibility = false, getShareableLinkData }) => {
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);
  const id = parsedQueryString?.id;
  const [prescreenData, setPrescreenData] = useState(dataPreview);
  const [shareLinkCopied, setShareLinkCopied] = useState({ open: false, vertical: 'top', horizontal: 'center' });
  const { vertical, horizontal, open } = shareLinkCopied;

  useEffect(() => {
    if (id) setPrescreenData(dataPreview);
  }, [dataPreview]);

  const handleShareableLink = () => {
    if (previewShareLinkData?.length === 0) {
      getShareableLinkData().then((res) => {
        navigator.clipboard.writeText(res?.data?.client_access_url).then(() => {
          setShareLinkCopied({ open: true, vertical: 'top', horizontal: 'center' });
        })
      })
    }

    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(previewShareLinkData).then(() => {
      setShareLinkCopied({ open: true, vertical: 'top', horizontal: 'center' });
    })
  }

  const questionRow = (data, index) => {
    const getDefaultSelected = data.answer == "OPTION_ONE" ? 1 : data.answer == "OPTION_TWO" ? 2 :
      data.answer == "OPTION_THREE" ? 3 : data.answer == "OPTION_FOUR" ? 4 : 1;

    const optionArray = [];
    if (data?.option1) {
      optionArray.push(data?.option1)
    }
    if (data?.option2) {
      optionArray.push(data?.option2)
    }
    if (data?.option3) {
      optionArray.push(data?.option3)
    }
    if (data?.option4) {
      optionArray.push(data?.option4)
    }


    return (
      <>
        <Box display='flex' flexDirection='column' padding={'5px'}>
          <Box display='flex'>
            <div className="question-text">
              Q{index + 1}.{" "}
              {data?.question_text}&nbsp;
            </div>
          </Box>

          {data?.question_code && <Box>
            <div className="question-code">
              {data?.question_code}&nbsp;
            </div>
          </Box>}

          <Box display='flex' >
            {data?.tags && <div className="question-tags"> {data?.tags?.join(", ")}</div>}
          </Box>

          <Box display='flex' >
            {data?.difficulty_level && <>
              <span className={`difficulty ${data?.difficulty_level?.toLowerCase() === 'easy' ? 'fg-green' : data?.difficulty_level?.toLowerCase() === 'medium' ? 'fg-gold' : 'fg-red'}`}>{data?.difficulty_level?.toUpperCase()}</span>
            </>}
          </Box>

          {optionArray?.map((data, index) => {
            return (<Box dsplay='flex' flexDirection={'row'}>
              <div>
                <span className={`configurations-label ml-2 p-1 ${getDefaultSelected === index + 1 ? 'selected-answer' : ''}`}>Option {index + 1}. {data}</span>
                <span className="ml-10 correct-answer">{getDefaultSelected === index + 1 &&
               <> <TaskAlt/> Correct Answer</>}</span>
              </div>
            </Box>)
          })}
        </Box>
      </>
    )
  }

  if (!prescreenData) {
    return <></>;
  }

  return (
    <div className="prescreen_preview_box">
      <Box display="flex" justifyContent="space-between" className="preview_topBar">
        <img src={logoiv} className="iv-logo" />
        <div className="navbar-text">PRESCREENING TEST</div>
        {copyLinkVisibility ? <div className="share-link" onClick={handleShareableLink}>
          <img src={copy} height="20px" width="20px" /> <p>Copy Link</p>
        </div> : <div></div>}
        {/* ----- Link copied popup ----*/}
        {shareLinkCopied?.open && <Snackbar anchorOrigin={{ vertical, horizontal }} autoHideDuration={1000}
          open={open} onClose={() => { setShareLinkCopied({ ...shareLinkCopied, open: false }); }} >
          <SnackbarContent style={{ backgroundColor: 'green', marginTop: "30px", fontWeight: '600' }}
            message={<span id="client-snackbar">✅ Link copied to clipboard</span>} />
        </Snackbar>}
      </Box>

      <div className="prescreen_preview_main_container">
        <div className="prescreen_preview_container">
          <div>
            <p className="preview_title">{prescreenData?.title}</p>
            <p className="preview_role">{prescreenData?.role}</p>
          </div>
          <hr />

          <div className="prescreen_preview_details">

            <h2>Test Configurations</h2>

            <Box display='flex'>
              <div className="configurations-label">Number of questions to ask each candidate:</div>
              <div className="configurations-value">{prescreenData?.number_of_questions}</div>
            </Box>


            <Box display='flex'>
              <div className="configurations-label">Cut off for screening candidates:</div>
              <div className="configurations-value">{prescreenData?.cut_off_percentage}%</div>
            </Box>


            <Box display='flex'>
              <div className="configurations-label">Total time allowed for each test:</div>
              <div className="configurations-value">{prescreenData?.time_allotted} mins</div>
            </Box>


          { prescreenData?.test_validity &&  <Box display='flex'>
              <div className="configurations-label">Test link validity:</div>
              <div className="configurations-value">{prescreenData?.test_validity} {prescreenData?.test_validity > 1 ? 'days' : 'day'} </div>
            </Box>}

           { prescreenData?.start_timestamp && <Box display='flex'>
              <div className="configurations-label">Test link start timestamp:</div>
              <div className="configurations-value">{moment.unix(prescreenData?.start_timestamp).format("MMM DD, hh:mm a")} </div>
            </Box> }
           { prescreenData?.time_window && <Box display='flex'>
              <div className="configurations-label">Time Window:</div>
              <div className="configurations-value">{prescreenData?.time_window} {prescreenData?.test_validity > 1 ? 'mins' : 'min'} </div>
            </Box>}
           { prescreenData?.last_allowed_submission_timestamp && <Box display='flex'>
              <div className="configurations-label">Test link last allowed timestamp :</div>
              <div className="configurations-value">{moment.unix(prescreenData?.last_allowed_submission_timestamp).format("MMM DD, hh:mm a")} </div>
            </Box>}
            <hr />

            <h2>Question Set</h2>

            <Box display='flex'>
              <div className="configurations-label">Total number of questions added:</div>
              <div className="configurations-value">{prescreenData?.questions?.length}</div>
            </Box>

            {prescreenData?.questions?.map((questionData, index) => {
              return questionRow(questionData, index);
            })}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
