import { useState, useEffect } from 'react';
import './sections.scss'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Select from "react-select";
import { PRESCREENING_TYPE } from '../../Constants';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

const selectStyles = {
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#9D9D9D',
        }
    },
}

const TestConfigurations = ({ preScreenData, setPreScreenData, canEdit = true }) => {
    const [preScreeningType, setpreScreeningType] = useState(PRESCREENING_TYPE.TEST_VALIDITY);
    const totalQuestionOptions = Array?.from(Array(11)?.keys())?.map((num) => ({ label: num + 10, value: num + 10 }));
    const cutOffOptions = Array?.from(Array(100)?.keys())?.map((num) => ({ label: num + 1 + "%", value: num + 1 }));
    const testValidityOptions = Array?.from(Array(7)?.keys())?.map((num) => ({ label: num + 1 + `${num + 1 == 1 ? " day" : " days"}`, value: num + 1 }));
    const timePeriodOptions = [
        { label: 2, value: 2 },
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 12, value: 12 },
        { label: 15, value: 15 },
    ]

    const handlePreScreenTitle = (e) => setPreScreenData({ ...preScreenData, title: e?.target?.value });
    const handlePreScreenNumberChange = (e) => setPreScreenData({ ...preScreenData, number_of_questions: e?.value });
    const handlePreScreenCutOffChange = (e) => setPreScreenData({ ...preScreenData, cut_off_percentage: e?.value });
    const handleTestValidityChange = (e) => { setPreScreenData({ ...preScreenData, test_validity: e?.value }); }
    const handlePreScreenTimePeriodChange = (e) => { setPreScreenData({ ...preScreenData, time_allotted: e?.value }); }

    useEffect(() => {
        // setpreScreeningType(preScreenData?.test_validity ? PRESCREENING_TYPE.TEST_VALIDITY : PRESCREENING_TYPE.TEST_START_TIMESTAMP)
    }, [preScreenData])

    const handleRadioOptionChange = (e)=>{
        if(e.target.value === PRESCREENING_TYPE.TEST_VALIDITY){
          setPreScreenData({...preScreenData, 
            start_timestamp: null,time_window: 0, last_allowed_submission_timestamp: null,
            test_validity : 2});
        }else{
          setPreScreenData({...preScreenData, test_validity : null });
        }
        setpreScreeningType(e?.target?.value);
      }

      const handleStartTimeStampChange =(date)=>{
        setPreScreenData({...preScreenData, start_timestamp: (date.getTime()/1000)});
      }
      
      const handleLastSubmissionTimestamp =(date)=>{
        setPreScreenData({...preScreenData, last_allowed_submission_timestamp: (parseInt(date.getTime()/1000))});
      }

      const handlePreScreenTimeWindow = (e)=> {
    
        const re = /^[0-9\b]+$/;
    
        const { name, value } = e.target;
        if (value === "" || re?.test(value)) {
          setPreScreenData({...preScreenData, time_window : e?.target?.value});
        }
      }

    return (
        <Box display='flex' flexDirection='column' className='prescreen-form'>
            <Typography className='header'> Test Configurations </Typography>

            <Box gap={1}>
                <Typography className="label-text mt-16">Test Name </Typography>
                <TextField
                    className="test-name-input"
                    size="small"
                    variant="outlined"
                    id="outlined-secondary"
                    name="test-name"
                    placeholder="Enter here"
                    sx={{ "& legend": { display: "none" }, backgroundColor: !canEdit ? "#e9ecef" : "#ffffff" }}
                    disabled={!canEdit}
                    value={preScreenData?.title}
                    onChange={handlePreScreenTitle}
                />

                <Typography className="label-text"> No. of questions to be asked to each candidate</Typography>
                <Select
                    name="numberOfQuestion"
                    placeholder={preScreenData?.number_of_questions || "Select"}
                    isDisabled={!canEdit}
                    className="select-dropdown"
                    styles={selectStyles}
                    options={totalQuestionOptions}
                    onChange={handlePreScreenNumberChange}
                    value={preScreenData?.number_of_questions}
                />

                <Typography className="label-text">Please mention the cut-off (in %) for screening candidates upon the questions asked in this round.
                    <Tooltip title={<Box className="fs-13 padding-10"> <Box className="mb-10">Set a cut-off for this test</Box>
                        <Box>Assigning a cut-off for this test will automatically filter out candidates based on their performance and will be listed in
                            <span className="fw-700">“Unscheduled”</span> tab.</Box></Box>} placement="top">
                        <span className="blue-text">Why?</span>
                    </Tooltip>
                </Typography>
                <Select
                    name="cutOff"
                    placeholder={preScreenData?.cut_off_percentage ? preScreenData?.cut_off_percentage + "%" : "cut-off (in %)"}
                    isDisabled={!canEdit}
                    className="select-dropdown"
                    styles={selectStyles}
                    options={cutOffOptions}
                    onChange={handlePreScreenCutOffChange}
                    value={preScreenData?.cut_off_percentage}
                />

                <Typography className="label-text">Total time (in mins) allowed for each test</Typography>
                <Select
                    name="pre-screen-time"
                    placeholder={preScreenData?.time_allotted ? preScreenData?.time_allotted + " mins" : "time period"}
                    isDisabled={!canEdit}
                    className="select-dropdown"
                    styles={selectStyles}
                    options={timePeriodOptions}
                    onChange={handlePreScreenTimePeriodChange}
                    value={preScreenData?.time_allotted}
                />

<Box>
                            {/* <FormControlLabel value={PRESCREENING_TYPE.TEST_VALIDITY} control={ */}
                            {/* <Radio size="small" disabled={!canEdit} />} label={<Box className="formlabel-text">Set the test link validity</Box>} onChange={handleRadioOptionChange} /> */}
                            <Typography className="label-text">Set the test link validity</Typography> 
                            <Select
                                name="prescreen-test-validity"
                                placeholder={preScreenData?.test_validity ? preScreenData?.test_validity + " days" : 2 + " days"}
                                isDisabled={!canEdit || preScreeningType === PRESCREENING_TYPE.TEST_START_TIMESTAMP}
                                className="select-dropdown ml-6"
                                styles={selectStyles}
                                options={testValidityOptions}
                                onChange={handleTestValidityChange}
                                value={preScreenData?.test_validity}
                            />
                        </Box>
            {  false &&  <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={preScreeningType}
                        style={{ gap: "2em" }}
                    >
                       
                        <Box sx={{ alignSelf: "baseline", marginRight: "2em", marginTop: "0.5em", fontSize: "13px", color: "#696969" }}>or</Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignSelf: "baseline" }}>
                            <FormControlLabel value={PRESCREENING_TYPE.TEST_START_TIMESTAMP} control={
                            <Radio size="small" disabled={!canEdit} />} label={<Box className="formlabel-text">Set the test start timestamp</Box>} onChange={handleRadioOptionChange} />
                            <Typography className="label-text ml-6">Please mention the start time for the test.</Typography>

                            <DatePicker
                                id="datetime"
                                className="custom-interview-input col-sm-12 date ml-6 date-input"
                                placeholderText="Select start timestamp"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeFormat="h:mm a"
                                autoComplete="off"
                                showTimeSelect
                                minDate={moment().toDate()}
                                timeIntervals={30}
                                timeCaption="Time"
                                disabled={!canEdit || preScreeningType === PRESCREENING_TYPE.TEST_VALIDITY}
                                onChange={handleStartTimeStampChange}
                                selected={preScreenData?.start_timestamp * 1000}
                            />

                            <Typography className="label-text ml-6">Please mention time window for the test in minutes.</Typography>

                            <TextField
                                className="test-name-input ml-6"
                                size="small"
                                variant="outlined"
                                id="outlined-secondary"
                                name="time_window"
                                placeholder="Enter window time for test validity (in minutes)"
                                sx={{ "& legend": { display: "none" }, backgroundColor: !canEdit ? "#e9ecef" : "#ffffff" }}
                                disabled={!canEdit || preScreeningType === PRESCREENING_TYPE.TEST_VALIDITY}
                                value={preScreenData?.time_window}
                                onChange={handlePreScreenTimeWindow}
                            />

                            <Typography className="label-text ml-6">Please mention last allowed submission time for the test.</Typography>

                            <DatePicker
                                id="datetime"
                                className="custom-interview-input col-sm-12 date ml-6 date-input"
                                placeholderText="Select last allowed submission timestamp"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeFormat="h:mm a"
                                autoComplete="off"
                                showTimeSelect
                                minDate={moment().toDate()}
                                timeIntervals={30}
                                timeCaption="Time"
                                disabled={!canEdit || preScreeningType === PRESCREENING_TYPE.TEST_VALIDITY}
                                onChange={handleLastSubmissionTimestamp}
                                selected={preScreenData?.last_allowed_submission_timestamp * 1000}
                            />

                        </Box>
                    </RadioGroup>
                </FormControl>}



            </Box>


        </Box>
    )
}
export default TestConfigurations;