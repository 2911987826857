import { useState, useEffect } from 'react';
import './sections.scss'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import deleteIcon from "../../../../../assets/images/delete-question.svg"
import editIcon from "../../../../../assets/images/editable.svg";
import viewIcon from "../../../../../assets/images/eye-grey.svg";
import addExternallyIcon from "../../../../../assets/images/add-external.svg";

import QuestionBankPopup from '../../Popups/QuestionBankPopup/QuestionBankPopup';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import LaunchIcon from '@mui/icons-material/Launch';
import emptyBox from '../../../../../assets/images/empty-box.svg'
import EditExternalQuestionTechnicallPopUp from "../../Popups/ExternalQuestionFormPopUp";
import Button from "@mui/material/Button";

import { styled as styled_mui } from "@mui/material/styles";

const CustomButton = styled_mui(Button)({
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    textTransform: "capitalize",
    fontSize: "13px",
    letterSpacing: "0.005em",
    background: "#fff",
    border: "1px solid #3d3d3d",
    borderRadius: "6px",
    color: "#3d3d3d",
    "&:hover": {
        background: "#fff",
        borderRadius: "6px",
        color: "#3d3d3d",
    },
});

const QuestionSet = ({
    interviewPrescreenData,
    setInterviewPrescreenData,
    getQuestionByFilters,
    technicalQuestionList,
    setTechnicalQuestionList,
    originalQuestionList,
    allowedTags,
    setAllowedTags,
    selectedQuestion,
    setSelectedQuestion,
    checkIsPresent,
    role_name,
    category_name,
    pageCount,
    currentPage,
    setCurrentPage,
    questionPerPage,
    totalQuestion,
    searchText,
    setSearchText,
}) => {

    const [formStatus, setFormStatus] = useState('ADD');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editExternalQuestionIndex, setEditExternalQuestionIndex] = useState();

    useEffect(() => {
        addPreviousData();
    }, []);

    const addPreviousData = () => {
        const selectedData = [];
        interviewPrescreenData?.questions?.forEach((item) => {
            if (item?.origin !== 'EXTERNAL') {
                selectedData?.push({
                    isChecked: true,
                    question: item,
                    questionId: item?.pre_screening_question_bank_id,
                });
            }
        });
        if (selectedData?.length > 0) {
            setSelectedQuestion((prev) => {
                return [...prev, ...selectedData];
            });
        }
    };

    const clearPreviousSelected = () => {
        const tempData = interviewPrescreenData?.questions?.filter(item => item?.origin === 'EXTERNAL')
        interviewPrescreenData.questions = tempData;
        setInterviewPrescreenData(() => {
            return { ...interviewPrescreenData };
        })
    }

    const addTechnicalQuestions = () => {
        if (selectedQuestion && selectedQuestion?.length > 0) {
            clearPreviousSelected();
            const copyData = { ...interviewPrescreenData };
            selectedQuestion?.forEach(item => {
                if (item?.isChecked) {
                    item.question.isFromQuestionBank = true;
                    item.question.pre_screening_question_bank_id = item?.questionId;
                    item.question.origin = 'QUESTION_BANK';

                    copyData.questions.push(item?.question);
                }
            });
            setInterviewPrescreenData(copyData);
        }
    };

    const renderQuestionRow = (data, index, deleteQuestion) => {

        const editExternalQuestion = (e, index) => {
            setEditModalOpen(true);
            setFormStatus('EDIT');
            setEditExternalQuestionIndex(index)
        }

        const viewQuestion = (e, index) => {
            setEditModalOpen(true);
            setFormStatus('VIEW');
            setEditExternalQuestionIndex(index);
        }

        return (
            <Box display='flex' alignItems='center' className='mt-10'>
                <Box display='flex' flexDirection='column' className='question-bg'>
                    <Typography className='question-title'> <span className='bold'> Q.&nbsp;</span>{`${data?.question_text}`}</Typography>
                    {/* <Typography className='question-statement'>{`${data?.question?.statement}`}</Typography> */}

                    <Box display='flex' justifyContent={'space-between'}>

                        <Typography className='answer-title mt-1'>
                            <span className='difficulty'>{data?.difficulty_level?.toUpperCase()}</span>
                            {data?.tags && data?.tags?.length > 0 && <><span>&#8226;</span> {data?.tags?.join(", ")}</>}
                        </Typography>
                        {data?.origin === 'EXTERNAL' &&
                            <Typography variant='string' className='externally-added' sx={{ fontSize: '10px' }}><LaunchIcon sx={{ transform: 'rotate(180deg)', fontSize: '15px' }} /> Externally Added</Typography>
                        }
                    </Box>
                </Box>
                {data?.origin === 'EXTERNAL' && <img src={editIcon} className='ml-10 pointer' onClick={(e) => editExternalQuestion(e, index)} />}
                {data?.origin !== 'EXTERNAL' && <img src={viewIcon} className='ml-10 pointer' onClick={(e) => viewQuestion(e, index)} />}
                <img src={deleteIcon} alt='delete' className='ml-10 pointer' onClick={() => deleteQuestion(index)} />

            </Box>
        )
    }

    const renderQuestions = () => {
        const addedQuestionBankList = interviewPrescreenData?.questions;

        const deleteQuestion = (index) => {
            const queType = interviewPrescreenData?.questions[index]?.origin;
            if (queType === 'EXTERNAL') {
                const copyData = { ...interviewPrescreenData };
                copyData?.questions.splice(index, 1);
                setInterviewPrescreenData(copyData)
            } else {
                const myId = interviewPrescreenData?.questions[index]?.pre_screening_question_bank_id;
                selectedQuestion?.forEach(item => {
                    if (item?.question?.id === myId) {
                        item.isChecked = !item.isChecked;
                    }
                });
                const copyData = { ...interviewPrescreenData };
                copyData?.questions.splice(index, 1);
                setInterviewPrescreenData(copyData);
                setSelectedQuestion(() => {
                    return [...selectedQuestion];
                })
            }
        }

        return (<Box display='flex' flexDirection='column'>

            <Typography className="heading-others">Added Questions List ({addedQuestionBankList?.length})</Typography>

            <>
                {addedQuestionBankList?.map((data, index) => {
                    return renderQuestionRow(data, index, deleteQuestion);
                })}

            </>

            {addedQuestionBankList?.length === 0 &&
                <Box className="noQuestions">
                    <img src={emptyBox} id="emptyBox" />
                    <Typography id="bannerText"><span id="bannerSpan">No questions</span> added yet for this section</Typography>
                </Box>
            }
        </Box>
        )
    }

    const renderQuestionBank = () => {
        return (
            <Box display='flex' flexDirection='column' className='mr-20'>
                <QuestionBankPopup
                    getQuestionByFilters={getQuestionByFilters}
                    interviewPrescreenData={interviewPrescreenData}
                    setInterviewPrescreenData={setInterviewPrescreenData}
                    type="technical"
                    questionList={technicalQuestionList}
                    setQuestionList={setTechnicalQuestionList}
                    originalQuestionList={originalQuestionList}
                    allowedTags={allowedTags}
                    setAllowedTags={setAllowedTags}
                    selectedQuestion={selectedQuestion}
                    setSelectedQuestion={setSelectedQuestion}
                    saveSelectedQuestions={addTechnicalQuestions}
                    role_name={role_name}
                    category_name={category_name}
                    totalQuestion={totalQuestion}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    questionPerPage={questionPerPage}
                    searchText={searchText}
                    setSearchText={setSearchText}
                />
            </Box>
        )
    }

    const handleChangedInterviewPrescreenData = (value) => {
        const copyData = { ...interviewPrescreenData };
        copyData.questions = [...value?.questions];
        setInterviewPrescreenData(copyData)

    }

    const openAddQuestionForm = () => {
        setFormStatus('ADD');
        setEditModalOpen(true);
    }

    return (

        <Box display='flex' flexDirection='column' className='prescreen-form'>
            <Typography className='header'> Question Set </Typography>

            <Box display='flex' flexDirection='column' className='tinted-background'>


                <Box className="fs-12 grey-text mb-10 mt-r-5">
                    <InfoOutlinedIcon sx={{ fontSize: 12, marginRight: "3px" }} /> Number of questions to be asked on the test is set to <span className="fw-700">{interviewPrescreenData?.number_of_questions || 10} questions.</span> </Box>

                <Box className="fs-12 grey-text mb-10 mt-r-5"><InfoOutlinedIcon sx={{ fontSize: 12, marginRight: "3px" }} />For best-case scenario, we suggest you to add atleast <span className="fw-700">{interviewPrescreenData?.number_of_questions ? interviewPrescreenData?.number_of_questions+10 : 20} questions.</span>
                    <Tooltip title={<Box className="fs-13 padding-10"><Box className="mb-10">Enter at least {interviewPrescreenData?.number_of_questions ? interviewPrescreenData?.number_of_questions+10 : 20} questions</Box>
                        To ensure minimal no. of identical test.</Box>} placement="top">
                        <span className="blue-text">Why?</span>
                    </Tooltip>
                </Box>

                <Box display='flex' alignItems={'center'} className="fs-12 grey-text mb-10 mt-r-5">
                    {renderQuestionBank()}

                    <CustomButton className='ml-1' onClick={(e) => openAddQuestionForm()}>
                    <img src={addExternallyIcon}/>
                        &nbsp; Add Externally
                    </CustomButton>

                </Box>


                {renderQuestions()}

                {editModalOpen &&
                    <EditExternalQuestionTechnicallPopUp
                        formStatus={formStatus}
                        editModalOpen={editModalOpen}
                        setEditModalOpen={setEditModalOpen}
                        questionData={interviewPrescreenData}
                        editExternalQuestionIndex={editExternalQuestionIndex}
                        submitChange={handleChangedInterviewPrescreenData} />
                }

            </Box>
        </Box>
    )
}
export default QuestionSet;
