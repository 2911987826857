import PreviewShared from "@iv/shared/components/pages/PrescreeningListForm/PrescreenPreview";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Box from "@material-ui/core/Box";
import SwtAlert from "../../../../Utils/SwtAlert";
import { getPrescreeningByPrescreeningId, getShareableLinkAPI } from "../../../../Apis/Pre_screening_api";

const Preview = () => {

    const [dataPreviewList, setDataPreviewList] = useState({});
    const [previewShareLinkData, setPreviewShareLinkData] = useState("");

    const user = useSelector((state) => state.user);
    const { search } = useLocation();
    const parsedQueryString = queryString.parse(search)
    const prescreen_id = parsedQueryString?.id;

    useEffect(() => {
        getPreviewData();
    }, [])

    const getPreviewData = async () => {
        getPrescreeningByPrescreeningId({}, prescreen_id).then(res => {
            if (res?.status === 200) {
                setDataPreviewList(res?.data);
            } else {
                SwtAlert(res?.data, 2000, "error", false);
            }
        }).catch(error => {
            SwtAlert(error.response, 2000, "error", false);
        });
    }

    const getShareableLinkData = async () => {
        return getShareableLinkAPI(prescreen_id).then(res => {
            if (res?.status === 200) {
                setPreviewShareLinkData(res?.data?.client_access_url);
            } else {
                SwtAlert(res?.data, 2000, "error", false);
            }
            return res;
        }).catch(error => {
            SwtAlert(error.response, 2000, "error", false);
            return error;
        });
    }

    return (
        <Box display='flex' padding={'20px'} justifyContent='center'>
            <PreviewShared dataPreview={dataPreviewList}
                previewShareLinkData={previewShareLinkData}
                copyLinkVisibility={false} //If want to show copy link, then pass copyLinkVisibility parameter.
                getShareableLinkData={getShareableLinkData}
            />
        </Box>
    )

}

export default Preview;