import React from 'react';
import zipy from 'zipyai';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import Main from './DemoPages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react'
import './assets/base.css';
import './styles/index.scss';
import Toasts from './toasts';

const { persistor, store } = configureStore();

const rootElement = document.getElementById('root');

const renderApp = Component => {
  if(process.env.NODE_ENV === 'production') {zipy.init('65810f94');}
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate
				persistor={persistor}>
				<HashRouter>
					<Toasts />
					<Component />
				</HashRouter>
			</PersistGate>
		</Provider>,
		rootElement
	);
};

renderApp(Main);

if (module.hot) {
	module.hot.accept('./DemoPages/Main', () => {
		const NextApp = require('./DemoPages/Main').default;
		renderApp(NextApp);
	});
}
