import { useState, useEffect } from "react";

import CustomizedTables from "./Table";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Select from "react-select";
import CreatePrescreenPopup from "../Popups/CreatePrescreenPopup";
import { RenderNoDataForOverAllDetails } from '../../../atoms/NoData/NoDataBox';

import "./PrescreenList.scss";
const FormSelect = styled(Select)`
    display: inline-block;
    padding: 0;
    border-radius: 4px;
    margin: 0px 20px 0px 20px;
    width: 200px !important;
    align-content: center;

    > div {
      margin-left: 0;
      background-color: #f8f8f8;
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  `;
const PrescreenList = ({ roleResponse, prescreenList, client_id, filterList, canAddPrescreen=true , canSelectClient=false, clientList, onSelectClient, canDuplicatePrescreen=true}) => {
  const [roleOptions, setRoleOptions] = useState([]);
  const [role, setRole] = useState();
  const [category, setCategory] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);

  const roleChangeHandleMain = (option) => {
    const { value } = option;
    // if(value?.length!==0 && roleResponse[value]!==undefined)
    // {
    // const tempArr = [{ label: "Select all", value: "", name: "Select all" }];

    // value && roleResponse[value].forEach(item => {
    //   tempArr.push({ label: item, value: item, name: item });
    // });
    setRole(value || "");
    // setCategory("");
    filterList(value, "");
    // setCategoryOptions(tempArr);
    // }
  }

  const categoryChangeHandleMain = (option) => {
    const { value } = option;
    if (value?.length !== 0)
      setCategory(value);
    filterList(role, value);
  }

  useEffect(() => {
    if (roleResponse) {
      let tempArr = [{ label: "Select all", value: "", name: "Select all" }];
      for (let key in roleResponse) {
        tempArr?.push({ label: key, value: key, name: key });
      }
      setRoleOptions(tempArr);
    }
  }, [])
  return (
    <div className="prescreen_list_main_container">

      <Box minHeight="500px" className="main_container">
        <Grid container>
          <Grid item xs={3}>
            <p className="font-16 bold-500">Available Tests</p>
            <p className="font-13 bold-400 fg-gray nmt-20">
              List of prescreening templates saved currently.
            </p>
          </Grid>

         {canSelectClient &&
          <Grid item xs={3}>
            <FormSelect
              className="custom-history-input"
              options={clientList}
              placeholder="All Clients"
              onChange={(selClient) => onSelectClient(selClient, role, category)}
              name="selectCategoryOption"
              isSearchable
            />
          </Grid>}

          <Grid item xs={3}>
            <FormSelect
              className="custom-history-input"
              options={roleOptions}
              placeholder="All Roles"
              onChange={roleChangeHandleMain}
              name="selectRoleOption"
              isSearchable
              value={role ? { label: role, value: role } : ""}
            />

          </Grid>
          
         {canAddPrescreen &&
          <Grid item xs={3} className="create-btn-main">
            <CreatePrescreenPopup roleResponse={roleResponse} roleOptions={roleOptions} defaultRole={role} defaultCategory={category} client_id={client_id} className="font-14 bold-600" />
          </Grid>
         }
        </Grid>
        <Divider />
        <Grid>
          {
            prescreenList ? <CustomizedTables client_id={client_id} prescreenList={prescreenList} canDuplicatePrescreen={canDuplicatePrescreen} canSelectClient={canSelectClient} clientList={clientList} /> 
            : <RenderNoDataForOverAllDetails firstMessage={"No prescreening tests"} secondMessage={"created for any role."} lastMessage={"Set template for any role."} callbackTitle="Set template" />
          }

        </Grid>
      </Box>
    </div>
  );
};

export default PrescreenList;
