import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import "./PrescreenList";
import eyeIcon from "../../../../assets/images/eye.svg";
import pencilIcon from "../../../../assets/images/pencil.svg";
import DuplicatePrescreenPopup from "../Popups/DuplicatePrescreenPopup";
import { Link } from 'react-router-dom';
import { RenderNoDataForOverAllDetails } from '../../../atoms/NoData/NoDataBox';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#3D3D3D",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#EEF2FF"
  },
  '&:nth-of-type(even)': {
    backgroundColor: "white",
  },
  'th,td,tr': {
    textAlign: "left"
  },
  'table,td,tr,th,thead,tbody': {
    boxShadow: "none",
    border: "none"
  },

  // hide last border
  '&:last-child td, &:last-child th': {
    border: "none",
    color: "#3D3D3D",
    boxShadow: "none",

  },
}));

function createData(Name, Role, Category, id, role, category) {
  return { Name, Role, Category, id, role, category };
}

export default function CustomizedTables({ client_id, prescreenList, canDuplicatePrescreen, canSelectClient, clientList }) {
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {

    if (prescreenList) {
      let arr = [];
      prescreenList?.forEach(item => {
        arr.push(createData(item?.title, item?.role, item?.round_category, item?.id, item?.role, item?.round_category));
      });
      setRows(arr);
    }
  }, [prescreenList])
  
  if (prescreenList?.length == 0) {
    return <RenderNoDataForOverAllDetails firstMessage={"No prescreening test"} secondMessage={"created for this role."} lastMessage={"Please create a prescreening for this role."} callbackTitle="Set Prescreen test" />
  }
  else
    return (
      <TableContainer className="table-container" component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          className="table-main"
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left" className="bold-600">
                Test Name
              </StyledTableCell>
              <StyledTableCell align="left" className="bold-600">
                Role
              </StyledTableCell>
              {/* <StyledTableCell align="left" className="bold-600">
                Status
              </StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={row.Name+index}>
                <StyledTableCell component="th" scope="row">
                  {row.Name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.Role}</StyledTableCell>
                {/* <StyledTableCell align="right">{row.status}</StyledTableCell> */}
                <StyledTableCell align="right">
                  {
                    row?.id ? <Link className="text-decoration-none" to={`/prescreening/add?type=edit&client_id=${client_id}&id=${row?.id}`}><Box className="edit-icon">
                      <img src={pencilIcon} />

                      <a
                        className="bold-500 font-11 text-decoration-none"
                      >&nbsp;&nbsp;
                        Edit
                      </a>
                    </Box></Link> : "Error"
                  }

                </StyledTableCell>
               
               {canDuplicatePrescreen &&
                <StyledTableCell align="right">
                  <Box className="edit-icon">
                    &nbsp;&nbsp;
                    <a className="bold-500 font-11">
                      <DuplicatePrescreenPopup firstText="Duplicate" client_id={client_id} prescreen_id={row?.id} title={row?.Name} role={row?.role} category={row?.category} canSelectClient={canSelectClient} clientList={clientList} />
                    </a>
                  </Box>
                </StyledTableCell>
               }

                <StyledTableCell align="right">
                  {
                    row?.id ? <Link className="text-decoration-none" target="_blank" to={`/prescreening/preview?id=${row?.id}`}>
                      <Box className="edit-icon">
                        <img src={eyeIcon} />
                        <a
                          className="bold-500 font-11 text-decoration-none"
                        >&nbsp;&nbsp;
                          View
                        </a>
                      </Box>
                      </Link> : "Error"
                    }

                </StyledTableCell>
              </StyledTableRow>
            ))
            }

          </TableBody>
        </Table>
      </TableContainer>
    );
}
