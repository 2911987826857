import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import "./ExternalQuestionPopUp.scss";
import cogoToast from 'cogo-toast';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import "./ExternalQuestionForm.scss";


const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: '1084px',
    height: '500px',
    maxWidth: 'none',
    maxHeight: 'none',
  },
  dialogContent:{
      width:'100%'
  }
}))

const renderRadioButton = () => {
  return <Radio
      style={{ width: "30px", padding: 0, margin: '0px 0px 2px 0px' }}
      sx={{
          '&.Mui-checked': {
              color: '#123fbc',
          },
          '& .MuiSvgIcon-root': {
              fontSize: 20,
              padding: 0
          },
      }} />
}

const renderTextfields = (label, isOptional, name, value, handleChange, isMultiline, isDisabled) => {

  return (
      <>
          <Typography className='question-titles-technical'> {label}  <span>{isOptional ? ' (Optional)' : ""}</span> </Typography>          
          <TextField
              disabled={isDisabled}
              multiline={isMultiline}
              maxRows={isMultiline ? 2 : 1}
              rows={isMultiline ? 2 : 1}
              name={name}
              defaultValue={value}
              onChange={handleChange}
              variant="outlined"
              sx={{
                  padding: "0px",
                  "& legend": {
                      display: "none"
                  },
              }}
              inputProps={{
                  style: {
                      padding: "8px",
                  },
              }}
          />
      </>
  )
}

const renderExternalQuestionForm = (formData, setFormData, formStatus) => {
  const [changedKeyword, setChangedKeyWord] = useState("");

  const handleChange = (e) => {
      const copyData = { ...formData };
      const { name, value } = e?.target;

      {
          name === "questionText" ? copyData.questionText = value :
              name === "questionCode" ? copyData.questionCode = value :
                  name === "difficulty" ? copyData.difficultyLevel = value :
                      ``
      }

      setFormData(copyData);
  }

  const handleChangeTags = (e) => {
      setChangedKeyWord(e?.target?.value);
  }

  const handleKeypressTags = (e) => {
      //it triggers by pressing the enter key
      if (e?.key === 'Enter') {
          addTag();
      }
  };

  const addTag = () => {
      if (changedKeyword.length > 0) {
          const copyData = { ...formData };
          const newTag = copyData?.tags
          newTag.push(changedKeyword)
          setFormData(copyData)
          setChangedKeyWord("")
      }
  }

  const deleteTag = (index) => {
      formData.tags.splice(index, 1)
      setFormData({ ...formData });
  }

  const handleOptionSelect = (e, option) => {
      if (e?.target?.value && e.target.value !== '') {
          setFormData({
              ...formData,
              selectedOption: option,
              answer: option == "option1" ? "OPTION_ONE" : option == "option2" ? "OPTION_TWO" :
                  option == "option3" ? "OPTION_THREE" : "OPTION_FOUR",
          });
      }
  };

  const handleOptionChange = (e, optionNumber, i) => {
      let updatedOption = {
          ...formData?.options,
          [optionNumber]: e.target.value
      };

      checkEmpty(updatedOption)
      setFormData({
          ...formData,
          options: updatedOption
      });
  };

  const deleteOption = (deletedOption) => {
      const updatedOptions = {};
      let optionMatched = false;
      Object.keys(formData?.options).forEach((optionName, i) => {
          if (deletedOption === optionName) {
              optionMatched = true;
          } else {
              if (!optionMatched) {
                  updatedOptions[optionName] = formData?.options[optionName];
              } else {
                  updatedOptions[`option${i}`] =
                      formData?.options[`option${i + 1}`];
              }
          }
      });
      checkEmpty(updatedOptions)
      setFormData({
          ...formData,
          options: updatedOptions
      });
  };

  const addOption = () => {
      const optionsLength = Object.keys(formData?.options)?.length;
      checkEmpty(formData?.options)
      setFormData({
          ...formData,
          options: {
              ...formData?.options,
              [`option${optionsLength + 1}`]: ""
          }
      });
  };

  const checkEmpty = (updatedOption) => {
      let isError = false;
      Object.keys(updatedOption).every((option) => {
          if (!updatedOption[option]) {
              isError = true;
              return false;
          } else {
              return true;
          }
      })
      if (isError) {
          setFormData({ ...formData, isError: true })
      } else {
          setFormData({ ...formData, isError: false })
      }
  };

  return (
      <Box>
          <Box display='flex' flexDirection='column'>
              <Box display='flex' gap='5%'>
                  <Box display='flex' alignItems='center'>
                      <Box>
                          <Typography className='question-titles-technical'> Difficulty Level: </Typography>
                      </Box>

                      <RadioGroup row
                          disabled={formStatus === 'VIEW'}
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="difficulty"
                          sx={{ marginTop: '25px', marginLeft:'10px' }}
                          defaultValue={formData?.difficultyLevel?.toUpperCase()}
                          onChange={handleChange}>
                          <FormControlLabel disabled={formStatus === 'VIEW'} value="EASY" control={renderRadioButton()} label={<Typography className='checkbox-label-technical'>Easy</Typography>} />
                          <FormControlLabel disabled={formStatus === 'VIEW'} value="MEDIUM" control={renderRadioButton()} label={<Typography className='checkbox-label-technical'>Medium</Typography>} />
                          <FormControlLabel disabled={formStatus === 'VIEW'} value="HARD" control={renderRadioButton()} label={<Typography className='checkbox-label-technical'>Hard</Typography>} />
                      </RadioGroup>

                  </Box>
              </Box>

              <Box>
                  <Typography className='question-titles-technical'>Tags</Typography>
                  <Box display='flex' gap='5px'>
                      { formStatus !=='VIEW' && <><TextField
                          size="small"
                          className="tag-textfield"
                          maxRows={2}
                          rows={2}
                          name="tags"
                          value={changedKeyword}
                          onChange={(e) => handleChangeTags(e)}
                          placeholder="Type here..."
                          variant="outlined"
                          onKeyPress={handleKeypressTags}
                          sx={{
                              padding: "0px",
                              "& legend": {
                                  display: "none"
                              },
                              width: '400px'
                          }}
                          inputProps={{
                              style: {
                                  padding: "8px",
                                  width: '400px'
                              },
                          }}
                      />
                      <Box className='add-button-tag' onClick={addTag}>+</Box></>}
                      <Grid className='tags-parent' container columns={12}>
                          { formData?.tags?.length > 0 ?
                              formData?.tags?.map((tag, index) => {
                                  return (
                                      <Grid item className="tag" xs={2}>
                                          <Typography className="tag-text-technical">{tag}</Typography>
                                          {formStatus !== 'VIEW' && <CloseIcon className="cross-icon" onClick={() => deleteTag(index)} />}
                                      </Grid>
                                  )
                              })
                          : formStatus === 'VIEW' ? 'NA' : ''}
                      </Grid>
                  </Box>
              </Box>


           <Box className="questionbg">

              {renderTextfields("Enter Question Name", false, 'questionText', formData.questionText, handleChange, false, formStatus==='VIEW')}
              {(formStatus!=='VIEW' || formData.questionCode) && renderTextfields("Question Code", false, 'questionCode', formData.questionCode, handleChange, true, formStatus==='VIEW')}
              <Box className="option-div fs-12">
                  <span className={formData?.isError && "requireMark"}>Enter atleast three options and mark the correct option</span>
                  {Object.keys(formData?.options).map((option, i) => {
                      return (
                          <Box display="flex" alignItems="center">
                              <Box className="option-container">
                                  <input
                                      type="radio"
                                      className="radio-button"
                                      disabled={formStatus === 'VIEW'}
                                      value={formData?.options?.[option] || ''}
                                      checked={formData?.answer && option === formData?.selectedOption}
                                      onChange={(e) => handleOptionSelect(e, option)}
                                  />
                                  <label className="option-title fw-700 fs-13 ml-10"><span>{option.substring(0, 6)}&nbsp;{option.substring(6)}</span></label>
                                  <TextField
                                      size="small"
                                      id="outlined-secondary"
                                      variant="outlined"
                                      name="option_text"
                                      sx={{ "& legend": { display: "none" } }}
                                      className="option-div-text"
                                      multiline
                                      disabled={formStatus === 'VIEW'}
                                      value={formData?.options[option]}
                                      onChange={(e) =>
                                          handleOptionChange(e, option, i)
                                      }
                                  />
                              </Box>
                              {Object.keys(formData?.options)?.length > 3 && formStatus !=='VIEW' && (
                                  <Box onClick={() => deleteOption(option)} className="cursor-p" >
                                      <DeleteIcon height="18px" />
                                  </Box>
                              )}
                          </Box>
                      );
                  })}
                  {Object.keys(formData?.options).length < 4 && formStatus !=='VIEW' && <Box onClick={() => addOption()} className="add-option-button addoption">
                      + Add Option
                  </Box>}
              </Box>
             </Box>
          </Box>
      </Box>
  )
}

const ExternalQuestionPopUp = (props) => {
  const classes = useStyles();
  const { formStatus, editModalOpen, setEditModalOpen, questionData, editExternalQuestionIndex, submitChange } = props;
   
    const [formData, setFormData] = useState({
    tagField: "",
    tags: [],
    difficultyLevel: "",
    questionText: "",
    options: { option1: '', option2: '', option3: '' },
    answer: '',
    selectedOption: '',
    questionCode: '',
    isError: false
});

useEffect(() => {
    if (formStatus=== 'EDIT' || formStatus === 'VIEW') {

        const editableQuestionData = questionData?.questions?.[editExternalQuestionIndex];
        const getDefaultSelected = editableQuestionData.answer == "OPTION_ONE" ? "option1" : editableQuestionData.answer == "OPTION_TWO" ? "option2" :
            editableQuestionData.answer == "OPTION_THREE" ? "option3" : editableQuestionData.answer == "OPTION_FOUR" ? "option4" : "option1";


        const formData = {
            tags: editableQuestionData?.tags || [],
            difficultyLevel: editableQuestionData?.difficulty_level,
            questionText: editableQuestionData?.question_text || '',
            questionCode: editableQuestionData?.question_code || '',
            options: {
                option1: editableQuestionData?.option1 || '',
                option2: editableQuestionData?.option2 || '',
                option3: editableQuestionData?.option3 || '',
            },
            answer: editableQuestionData?.answer || '',
            selectedOption: getDefaultSelected
        }

        if (editableQuestionData?.option4) {
            formData.options.option4 = editableQuestionData?.option4
        }

        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                ...formData
            };
        });

    }
}, []);

  const handleClose = () => {
    setEditModalOpen(false);
  }

  const saveEditForm = () => {

    console.log(formData)
      if ( !(formData?.questionText && formData?.questionText !== ""
        && formData?.difficultyLevel && formData?.difficultyLevel !== ""
        && formData?.tags && formData?.tags?.length !== 0
        && formData?.options?.option1 && formData?.options?.option1?.length !== 0
        && formData?.options?.option2 && formData?.options?.option2?.length !== 0
        && formData?.options?.option3 && formData?.options?.option3?.length !== 0
        && formData?.answer && formData?.answer?.length !== 0)) {
          cogoToast.error("Please fill mandatory fields before proceeding")
          return;

        }

    const dataLoad = {
      tags: formData?.tags || null,
      difficulty_level: formData?.difficultyLevel && formData?.difficultyLevel?.length > 0 ? formData?.difficultyLevel : null,
      question_text: formData?.questionText || null,
      option1: formData?.options?.option1 || null,
      option2: formData?.options?.option2 || null,
      option3: formData?.options?.option3 || null,
      option4: formData?.options?.option4 || null,
      answer: formData?.answer || null,
      question_code: formData?.questionCode || null,
      origin: 'EXTERNAL',
    };

    const dupQuestionData = {...questionData};

    if(formStatus === 'ADD'){
      dupQuestionData.questions.push(dataLoad)
    }else  if(formStatus === 'EDIT'){
      dupQuestionData.questions[editExternalQuestionIndex] = dataLoad;
    }

    submitChange(dupQuestionData);
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={editModalOpen}
        classes={{ paper: classes.dialogWrapper }}
        className="dialog-parent-class"
      >

        <Box className="button-dialogTitle-box-technical" justifyContent={'space-between'} width={'100%'}>
          <DialogTitle className="dialog-title-technical" classes={{ paper: classes.dialogContent }}>
            <div>{formStatus === 'ADD' ? 'Add Question Externally': formStatus === 'EDIT' ? 'Edit Externally Added Question' : 'View Question'}</div>
          </DialogTitle>
          <div>
            {(formStatus === 'ADD' || formStatus === 'EDIT') && <Button className="saveButton-technical" onClick={saveEditForm}>Save Changes</Button>}
            <CloseIcon className="close-popup-technical" onClick={handleClose} />
          </div>
        </Box>

        <DialogContent 
        classes={{ paper: classes.dialogContent }}
        dividers>
        <div className="edit-external-form-parentDiv">
          {renderExternalQuestionForm(formData, setFormData, formStatus)}
      </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ExternalQuestionPopUp;