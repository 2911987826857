import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import styled from "styled-components";
import { styled as styled_mui } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import rightArrowHeadIcon from "../../../../../assets/images/right-arrow-head.svg";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import QuestionList from "./QuestionList";
import SwtAlert from "../../../../../Utils/SwtAlert";
import AddIcon from "@mui/icons-material/Add";
import rightArrowIcon from "../../../../../assets/images/right-arrow.svg";
import NoResult from "./NoResult";
import SkeltonLoading from "./SkeltonLoading";
import Select from "react-select";
import './QuestionBankPopup.scss';
import './Pagination.scss';
import ReactPaginate from "react-paginate";
import cogoToast from "cogo-toast";
const FormSelect = styled(Select)`
  display: inline-block;
  padding: 0;
  border-radius: 4px;
  margin: 0px 20px 0px 20px;
  align-content: center;

  > div {
    margin-left: 0;
    background-color: #f8f8f8;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const CustomButton = styled_mui(Button)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  textTransform: "capitalize",
  fontSize: "13px",
  letterSpacing: "0.005em",
  background: "#3D3D3D",
  borderRadius: "6px",
  color: "white",
  "&:hover": {
    background: "#3D3D3D",
    borderRadius: "6px",
    color: "white",
  },
});
const CustomTextField = styled_mui(TextField)({
  marginTop: "-5px",
  padding: "0px",
  "& .MuiInputBase-input": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "12px",
    letterSpacing: "0.005em",
    width: "240px",
    height: "32px",
    color: "#3D3D3D",
    borderRadius: "4px",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#858181",
    fontWeight: 400,
  },
  "& legend": {
    display: "none",
  },
});

const CustomCheckBox = styled_mui(Checkbox)({
  "&.Mui-checked": {
    color: "#123FBC",
  },
});

const CustomFont = styled.div`
  font-family: "Poppins";
  font-style: normal;
  letter-spacing: 0.005em;
  position: relative;
  top: 15px;

  .custom-history-input {
    height: 38px;
    width: 100%;
    position: relative;
    left: -21px;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f8f8f8;
    box-sizing: border-box;
  }
  .top-heading {
    font-size: 18px;
  }

  .fg-black {
    color: #3d3d3d;
  }
  .font-10 {
    font-size: 10px;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-600 {
    font-weight: 600;
  }
  .content-container {
    height: 65vh;
  }
  .left-box {
    background: #f7f7f7;
    border-radius: 8px;
    width: 29%;
    padding: 20px;
    height: 95%;
  }
  .right-box {
    width: 71%;
    padding: 20px;
    height: 95%;
  }

  .font-style {
    font-family: "Poppins";
    font-style: normal;
    letter-spacing: 0.005em;
  }

  .difficulty-level {
    font-weight: 400;
    font-size: 13px;
    margin-top: 15px;
  }

  .checkbox-label {
    letter-spacing: 0.005em;
    font-size: 12px;
    font-weight: 500;
  }

  .checkbox-label-role {    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: #3D3D3D;
  }

  .sub-heading {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.005em;
    color: #838383;
    position: relative;
    top: -5px;
  }

  .tags-heading {
    font-weight: 400;
    font-size: 13px;
    color: #3d3d3d;
  }
  .close-icon {
    font-size: 10px;
    font-weight: 400;
    margin: auto 0px auto auto;
  }
  .tags {
    height: 22px;
    background: #3d3d3d;
    border-radius: 6px;
    padding: 3px 6px 3px 6px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    margin-right: 8px;
    cursor: pointer;
  }
  .tags:hover {
    background: #525252;
  }

  .arrow-head {
    margin-left: 15px;
    float: right;
  }

  .search-icon {
    font-size: 18px;
    color: #858181;
  }
  .right-top-heading {
    font-weight: 500;
    font-size: 14px;
    color: #2b2b2b;
    margin-top: 30px;
  }
  .right-sub-heading {
    font-weight: 500;
    font-size: 12px;
    color: #ababab;
    margin-top: -13px;
  }
  .question-container {
    width: 100%;
    height: 74.5%;
    overflow: scroll;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    background: #f8f9fd;
  }
`;

const ConfirmButton = styled_mui(Button)({
  height: "40px",
  width: "180px",
  float: "right",
  fontWeight: "600",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontSize: "14px",
  color: "white",
  textAlign: "center",
  marginRight: "3.5%",
  background: "#123fbc",
  borderRadius: "10px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#123fbc",
    color: "white",
  },
});
const ApplyFilterButton = styled_mui(Button)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  textTransform: "capitalize",
  letterSpacing: "0.005em",
  fontWeight: "600",
  fontSize: "12px",
  background: "#f18d17",
  borderRadius: "6px",
  color: "white",
  height: "30px",
  "&:hover": {
    background: "#f18d17",
    color: "white",
  },
});
function QuestionBankPopup({
  getQuestionByFilters,
  interviewPrescreenData, 
  setInterviewPrescreenData,
  type,
  questionList,
  setQuestionList,
  originalQuestionList,
  allowedTags,
  setAllowedTags,
  selectedQuestion,
  setSelectedQuestion,
  saveSelectedQuestions,
  role_name,
  category_name,
  totalQuestion, 
  pageCount,
  currentPage,
  setCurrentPage,
  questionPerPage,
  searchText,
  setSearchText,
}) {
  const [open, setOpen] = React.useState(false);
  const [openTopicDialog, setTopicDialogVisibility] = React.useState(false);
  const [topic, setTopic] = React.useState(null);

  const [tags, setTags] = React.useState([]);
  const [difficultyLevel, setDifficultyLevel] = React.useState({
    EASY:false,
    MEDIUM:false,
    HARD:false
  });
  const [roleChecked, setRoleChecked] = React.useState(false);
  const [isLoading,setIsLoading] = React.useState(false);
  const [originalFilterList,setOriginalFilterList] = React.useState(questionList);  

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setTopic(null);
    setOpen(false);
    localStorage.removeItem("filterApplied");
  };

  const filterQuestions = (data)=>{
    let temp = [];
    if(type==='technical')
    {
      temp = data?.filter(
        (item) => item?.type !== "TECH_THEORY"
      );
    }
    else
    {
      temp = data?.filter(
        (item) => item?.type === "TECH_THEORY"
      );
    }
    setQuestionList(temp);
    setOriginalFilterList(temp);
  }
  const getQuestionByFiltersFn = (selectedPage=0, text, tags, difficultyLevel, roleChecked) => {
    let arr = [];
    if(difficultyLevel?.EASY === true)
    {
      arr.push("EASY");
    }
    if(difficultyLevel?.MEDIUM === true)
    {
      arr.push("MEDIUM");
    }
    if(difficultyLevel?.HARD === true)
    {
      arr.push("HARD");
    }
    const payload = {
      tags: tags,
      difficulty_level:arr,
      role: roleChecked ? [`${role_name}`] : [],
      page_size : questionPerPage,
      page_number : selectedPage ? selectedPage + 1 : 1,
      query_text:text?.technical ? text?.technical : text?.theoretical ? text?.theoretical : text,
    };
    setCurrentPage(selectedPage);
    localStorage.setItem("filterApplied", JSON.stringify(arr));
    getQuestionByFilters(payload, [type]).then(res=>{
      filterQuestions(res?.data);
    }).catch(error=>{
      cogoToast.error(error?.response?.data?.toString() || error?.data?.data?.toString() || 
      error?.data?.toString() || error?.toString() || "Could not fetch question",{
        position:"top-right"
      })
    })
  };

  const handleConfirm = ()=>{
    saveSelectedQuestions(topic);
    handleClose();
  }

  const searchQuestionList = (text, tags, difficultyLevel, roleChecked) => {
    getQuestionByFiltersFn(0, text, tags, difficultyLevel, roleChecked);
  };
  const debounce = (fn, delay) => {
    let timer;
    return function () {
      let context = this;
      let args = arguments;
      clearInterval(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  };

  const handleSearch = React.useCallback(debounce((e,  tags, difficultyLevel, roleChecked) => searchQuestionList(e, tags, difficultyLevel, roleChecked), 300), []);

  const handleSearchInput = (e) => {
    let tempText = {...searchText};
    if(type === "technical")
    {
      tempText.technical = e?.target?.value;
    }
    else
    {
      tempText.theoretical = e?.target?.value;
    }
    setSearchText(tempText);
    handleSearch(e?.target?.value, tags, difficultyLevel, roleChecked);
  };
  const handleTagsInput = (e) => {
    const value = e?.value;
    if (value && value?.length > 0) {
      setTags((prev) => {
        return [...prev, value];
      });
      let tempArr = allowedTags?.filter((tag) => tag?.value !== value);
      setAllowedTags(tempArr);
    }
  };

  const deleteTag = (tag) => {
    let tempArr = tags?.filter((item) => item !== tag);
    setTags(tempArr);
    tempArr = [];

    let isAllowed = false;
    let tempTag = [];
    allowedTags?.forEach((item) => {
      if (item?.value === "") {
        isAllowed = true;
      }
      if (isAllowed) {
        tempTag.push(item);
        tempTag.push({ label: tag, value: tag, key: tag });
        isAllowed = false;
      } else {
        tempTag.push(item);
      }
    });
    setAllowedTags(tempTag);
  };
  const handleCheckbox = (e)=>{
      const {name,checked} = e?.target;
      difficultyLevel[name] = !difficultyLevel[name];  
      setDifficultyLevel({...difficultyLevel});
  }
  
  const handleRoleCheckbox = (e) => {
    setRoleChecked(!roleChecked);
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    const text = (type === 'technical') ? searchText?.technical : searchText?.theoretical;
    getQuestionByFiltersFn(selectedPage, text, tags, difficultyLevel, roleChecked);
  }

  const customStyles = {
    control: base => ({
      ...base,
      maxHeight: '1em'
    })
  };
 React.useEffect(()=>{
  setRoleChecked(true);
  setDifficultyLevel({
    EASY:false,
    MEDIUM:false,
    HARD:false
  })
 },[]);
  return (
    <div>
      <CustomButton onClick={handleClickOpen}>
        <AddIcon className="font-10" />
        &nbsp; Add from Question Bank
      </CustomButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: { borderRadius: 14 },
        }}
      >
        <DialogContent sx={{ overflow: "hidden" }} dividers>
          <DialogContentText id="alert-dialog-description">
            <CustomFont>
              <h3 className="top-heading fg-black font-600">Question Bank</h3>
              <p className="sub-heading font-style">
                We have a list of questions for you.{" "}
              </p>
            </CustomFont>
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <CustomFont>
            <Box
              className="content-container"
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
            >
              <Box className="left-box">
                <Box  sx={{maxWidth: 288}}>
                <FormControlLabel
                    control={
                      <CustomCheckBox
                        checked={roleChecked ? "checked" : ""}
                        onClick={handleRoleCheckbox}
                        name="ROLE"
                        size="small"
                      />
                    }
                    label={
                      <span className="checkbox-label-role">Show <span style={{fontWeight: 600}}>{role_name && role_name?.length>0?role_name:""} </span>Questions only</span>
                    }
                  />
                </Box>
                <p className="difficulty-level font-style">Difficulty Level</p>
                <Box display="flex" marginTop="-20px" flexWrap="wrap">
                  <FormControlLabel
                    control={
                      <CustomCheckBox
                        checked={difficultyLevel?.EASY ? "checked" : ""}
                        onClick={handleCheckbox}
                        name="EASY"
                        size="small"
                      />
                    }
                    label={
                      <span className="checkbox-label font-style">Easy</span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckBox
                        checked={difficultyLevel?.MEDIUM ? "checked" : ""}
                        onClick={handleCheckbox}
                        name="MEDIUM"
                        size="small"
                      />
                    }
                    label={
                      <span className="checkbox-label font-style">Medium</span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <CustomCheckBox
                        checked={difficultyLevel?.HARD ? "checked" : ""}
                        onClick={handleCheckbox}
                        name="HARD"
                        size="small"
                      />
                    }
                    label={
                      <span className="checkbox-label font-style">Hard</span>
                    }
                  />
                </Box>
                <p className="tags-heading font-style">Tags</p>
                <FormSelect
                  className="custom-history-input"
                  options={allowedTags}
                  placeholder="Select Tags"
                  value={allowedTags[0]}
                  onChange={handleTagsInput}
                  name="selectAllowedTags"
                  isSearchable
                />
                <Box
                  display="flex"
                  marginTop="15px"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  width="100%"
                  rowGap="20px"
                >
                  {tags?.map((tag) => {
                    return (
                      <Box
                        onClick={() => deleteTag(tag)}
                        className="tags font-style"
                      >
                        {tag}
                        &nbsp;&nbsp;
                        <CloseIcon className="close-icon" />
                      </Box>
                    );
                  })}
                </Box>
                <Typography align="right" marginTop="20px">
                  <ApplyFilterButton onClick={()=>getQuestionByFiltersFn(0, type === "technical"?searchText?.technical:searchText?.theoretical, tags, difficultyLevel, roleChecked)}>
                    Apply Filters
                    <img className="arrow-head" src={rightArrowHeadIcon} />
                  </ApplyFilterButton>
                </Typography>
              </Box>
              <Box className="right-box">
                <CustomTextField
                  size="small"
                  id="input-with-icon-textfield"
                  className="tags-input-box"
                  variant="outlined"
                  placeholder="Search question with keywords"
                  value={type === "technical"?searchText?.technical:searchText?.theoretical}
                  onChange={(e) => handleSearchInput(e)}
                  sx={{
                    width: "100%",
                    height: "32px",
                    "& legend": {
                      display: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="search-icon" />
                      </InputAdornment>
                    ),
                  }}
                />
                <p className="right-top-heading">
                  Available questions ({totalQuestion})
                </p>
                <p className="right-sub-heading">
                  (Select the questions that you want to include in your test
                  and click on the Confirm button.)
                </p>
                <Box className="question-container">
                  { isLoading?<SkeltonLoading/>:questionList && questionList?.length > 0 ? (
                    <QuestionList 
                      type={type} 
                      questionList={questionList} 
                      selectedQuestion={selectedQuestion} 
                      setSelectedQuestion={setSelectedQuestion} 
                      topic={topic} 
                      currentPage={currentPage} 
                      questionPerPage={questionPerPage}  
                      />
                  ) : (
                    <NoResult />
                  )}
                </Box>
                {pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    initialPage={currentPage}
                    forcePage={currentPage}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    />
                  )}

              </Box>
            </Box>
            <ConfirmButton onClick={handleConfirm}>
              Confirm &nbsp;&nbsp;
              <img src={rightArrowIcon} />
            </ConfirmButton>
            <br />
            <br />
          </CustomFont>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default QuestionBankPopup;
