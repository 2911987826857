import PrescreeningListShared from "@iv/shared/components/pages/PrescreeningListForm/PrescreenList";

import { useEffect, useState } from "react";

import SwtAlert from "../../../../Utils/SwtAlert";

import { getAllPrescreening } from "../../../../Apis/Pre_screening_api";
import { getAllRolesRoundCategoriesDict } from "../../../../Apis/positions_api";
import { connect } from "react-redux";

const PrescreeningList = (props) => {
  const [roleResponse, setRoleResponse] = useState([]);
  const [prescreeningList, setPrescreeningList] = useState([]);
  const [client_id, setClientId] = useState("");

  let { clients } = props;
  let clientsList = [];

  for (let i = 0; i < clients?.length; i++) {
    const data = clients[i];
    data.label = clients[i]?.client_name;
    data.value = clients[i]?.id;
    clientsList.push(data);
  }
  clients = clientsList;

  const getRole = async () => {
    getAllRolesRoundCategoriesDict()
      .then((res) => {
        if (res) {
          setRoleResponse(res);
        } else {
          SwtAlert(res, 2000, "error", false);
        }
      })
      .catch((error) => {
        SwtAlert(error?.data, 2000, "error", false);
      });
  };

  const getPrescreeningList = (tempRole, tempCategory,  clientId=client_id) => {
    if(!clientId)
    return;

    const payload = {};
    if (tempRole && tempRole?.length > 0) {
      payload["role"] = tempRole;
    }
    if(clientId){
      payload['client_id'] = clientId
    }
    getAllPrescreening(payload)
      .then((res) => {
        if (res?.status === 200) {
          setPrescreeningList(res?.data);
        } else {
          SwtAlert(res?.data, 2000, "error", false);
        }
      })
      .catch((error) => {
        SwtAlert(error?.response, 2000, "error", false);
      });
  };
  
  const handleClientSelect=(client, role) => {
    setClientId(client?.id)
    getPrescreeningList(role, '', client?.id)
  }

  useEffect(() => {
    getRole();
    // getPrescreeningList();
  }, []);

  return (
      prescreeningList && Object.keys(roleResponse)?.length > 0 ? (
        <PrescreeningListShared
          client_id={client_id}
          prescreenList={prescreeningList}
          filterList={getPrescreeningList}
          roleResponse={roleResponse}
          prescreeningList={prescreeningList}
          canAddPrescreen={false}
          canDuplicatePrescreen={false}
          canSelectClient={true}
          clientList = {clients}
          onSelectClient={handleClientSelect}
        />
      ) : (
        ""
      )
  );
};

const mapStateToProps = (state) => ({
    clients: state.clients,
  });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrescreeningList);
