import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled as styled_mui } from '@mui/material/styles';
import styled from "styled-components";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';
import Select from "react-select";
import duplicateIcon from "../../../../assets/images/duplicate-icon.svg";

const FormSelect = styled(Select)`
  display: inline-block;
  width: 150px;
  padding: 0;
  border-radius: 4px;
  margin: 0px 20px 0px 20px;
  width: 300px;
  align-content: center;
  font-family:'Poppins';
  > div {
    margin-left: 0;
    background-color: #f8f8f8;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const BootstrapDialog = styled_mui(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DuplicatePrescreenPopup({ firstText, client_id, prescreen_id, role, category, title, type, canSelectClient, clientList }) {
  const [open, setOpen] = React.useState(false);
  const [unique_id, setUnique_id] = React.useState(client_id);
  const handleClient = (data) => {
    setUnique_id(data.id);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomContainer style={{ display: "inline-block" , whiteSpace:'nowrap'}}>
      <span onClick={handleClickOpen}>
        {
          type !== 'position' ? <><img src={duplicateIcon} />&nbsp;&nbsp;</>
            : ""
        }

        {firstText}
      </span>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        className="font-style"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography fontFamily="Poppins" fontSize="22px" fontWeight="400">
          Duplicate Template
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CustomBox>
            <Typography gutterBottom className="poppins font-14 bold-400 fg-black">
              Do you want to make a duplicate template of
            </Typography>
            <Typography className="poppins font-18 bold-600 fg-black" gutterBottom>
              {
                title ? title : ""
              }
            </Typography>
            <Typography className="poppins font-14 bold-600 fg-gray" gutterBottom>
              {
                category ? category : ""
              }
            </Typography>
              <Typography className="poppins font-16 bold-600 fg-black-2" gutterBottom>
                - {role ? role : ""}
              </Typography>
              <br/>
              {canSelectClient &&
              <Typography className="poppins font-16 bold-400 fg-black">
                For client : 
              <FormSelect
              options={clientList}
              placeholder="All Clients"
              onChange={handleClient}
              name="selectCategoryOption"
              isSearchable
              />
             </Typography>}
              {
                prescreen_id ? <Link onClick={handleClose} target="_blank" to={`/prescreening/add?type=duplicate&client_id=${unique_id}&id=${prescreen_id}`}>

                  <Button className="proceed_button_duplicate">Proceed</Button>
                </Link> : "Error"
              }
          </CustomBox>
        </DialogContent>
      </BootstrapDialog>
    </CustomContainer>
  );
}


const CustomContainer = styled.div`
    .font-style {
    font-family: 'Poppins';
    letter-spacing: 0.005em;
}

`;

const CustomBox = styled.div`
display:flex;
flex-direction:column;
align-items:space-evenly;
.poppins{
  font-family:'Poppins';
}
.proceed_button_duplicate {
    width:150px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.005em;
    color: white;
    background-color: #123FBC;
    float: right;
    margin:30px 30px 20px auto;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 8px;
}
.proceed_button_duplicate:hover
{
  background-color: #123FBC;
  color:white;
}
.nmt-15 {
    margin-top: -15px;
}

.fg-black {
    color: #3D3D3D;
}

.fg-black-2 {
    color: #494949;
}

.fg-gray {
    color: #AAAAAA;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}


.font-18 {
    font-size: 18px;
}

.bold-600 {
    font-weight: 600;
}

.bold-400 {
    font-weight: 400;
}
`;
